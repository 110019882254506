import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Row, Container, Card, Col } from "react-bootstrap";
import { Link } from "gatsby";
import ImageOne from "../images/10.jpg";
import ImageTwo from "../images/11.jpg";
import ImageThree from "../images/12.jpg";
import ImageFour from "../images/13.jpg";
import ImageFive from "../images/21.jpg";
import ImageSeven from "../images/35.jpg";
import ImageEight from "../images/36.jpg";
import ImageNine from "../images/25.jpg";
import ReactImageGallery from "react-image-gallery";
const images = [
  {
    original: ImageOne,
    originalAlt: "View of Kit Hill",
    originalWidth: 800,
    originalHeight: 600,
  },
  {
    original: ImageNine,
    originalAlt: "Dartmoor highland cows.",
    originalWidth: 700,
    originalHeight: 700,
  },
  {
    original: ImageSeven,
    originalAlt: "The River Tamar bridge. Joining Devon and Cornwall.",
    originalWidth: 700,
    originalHeight: 700,
  },
  {
    original: ImageTwo,
    originalAlt: "River Tamar",
    originalWidth: 800,
    originalHeight: 600,
  },

  {
    original: ImageThree,
    originalAlt: "Hay bales in a field",
    originalWidth: 800,
    originalHeight: 600,
  },
  {
    original: ImageEight,
    originalAlt: "A nearby field",
    originalWidth: 800,
    originalHeight: 533,
  },
  {
    original: ImageFive,
    originalAlt: "Dartmoor",
    originalWidth: 800,
    originalHeight: 600,
  },
  {
    original: ImageFour,
    originalAlt: "Local cows",
    originalWidth: 712,
    originalHeight: 712,
  },
];

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="jumbotron">
        <div className="container">
          <h1 className="display-5">Welcome!</h1>
          <ReactImageGallery
            lazyLoad={true}
            showThumbnails={false}
            items={images}
          />

          <br />
          <p className="lead">
            Rhylla Cottage and Rhylla Retreat are both spacious 1 bedroom
            cottages with modern facilities.
          </p>
          <p className="lead">
            Situated in an elevated position within the Tamar Valley, Rhylla
            Cottage & Rhylla Retreat have wonderful views of the countryside and
            river valley below and offer a perfect place to stay for people who
            enjoy peace, tranquillity and cosy home from home comforts.
          </p>
        </div>
      </div>

      <Container fluid>
        <Row>
          <Col style={{ marginBottom: 24 }}>
            <Card>
              <Card.Body>
                <Card.Title>Our accommodation</Card.Title>
                <Card.Text>
                  Rhylla Cottage & Rhylla Retreat are ideally situated for those
                  wanting an abundance of local walks, cycling trails and other
                  activities.
                  <br />
                  <br />
                  Both Rhylla Cottage & Rhylla Retreat are available to rent
                  separately on a weekly basis or for short breaks. They can
                  also be booked together for up to 4 guests.
                </Card.Text>

                <div
                  style={{
                    display: "flex",
                    gap: "24px",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Link
                    to="/accommodation/rhylla-cottage"
                    className="btn btn-primary btn-lg"
                  >
                    Rhylla Cottage Availability
                  </Link>
                  <Link
                    to="/accommodation/rhylla-retreat"
                    className="btn btn-primary btn-lg"
                  >
                    Rhylla Retreat Availability
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ marginBottom: 24 }}>
            <Card>
              <Card.Body>
                <Card.Title>
                  Interested in staying or have a question?
                </Card.Title>
                <Card.Text>
                  Get in contact with us and we'll respond as soon as possible!
                </Card.Text>
                <Link to="/contact-us" className="btn btn-primary btn-lg">
                  Contact Us
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default IndexPage;
